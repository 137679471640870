@mixin buttons() {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.3rem !important;
  background-color: $yellow !important;
  color: $dark !important;
  transition: all 0.3s ease-in-out !important;
  cursor: pointer !important;
  font-size: 1rem !important;

  &:hover {
    background-color: lighten($yellow, 10%) !important;
  }
  &:active {
    background-color: darken($yellow, 20%) !important;
  }
}

@mixin imgfilters($filter) {
  @if $filter == blur {
    filter: blur(5px);
  } @else if $filter == brightness {
    filter: brightness(0.4);
  } @else if $filter == contrast {
    filter: contrast(200%);
  } @else if $filter == grayscale {
    filter: grayscale(50%);
  } @else if $filter == huerotate {
    filter: hue-rotate(90deg);
  } @else if $filter == invert {
    filter: invert(75%);
  } @else if $filter == opacity {
    filter: opacity(25%);
  } @else if $filter == saturate {
    filter: saturate(30%);
  } @else if $filter == sepia {
    filter: sepia(60%);
  } @else {
    filter: none;
  }
}
