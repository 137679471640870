.blogs {
  .blog-card {
    background-color: $dark;
    box-shadow: rgba(179, 179, 144, 0.2) 0px 1px 2px 0px;
    h3 {
      line-height: 2.5rem;
    }
    p {
      line-height: 1.8rem;
      color: #a1a1aa;
    }
    .chip {
      background-color: $yellow;
    }
    .author {
      color: $yellow;
      font-style: italic;
    }
  }
}
