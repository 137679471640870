.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: auto;
}

.modal {
  width: 95%;
  max-width: 700px;
  background-color: $dark;
  color: $white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 1rem 2rem;
  color: $yellow;
}

.modal-header h1 {
  margin: 0;
  font-size: 1.2rem;
}

.modal-content {
  padding: 1rem 2rem;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p::first-letter {
    margin-left: 16px;
  }
}

.close-modal {
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}
@media (max-width: 500px) {
  .modal {
    font-size: 0.8rem !important;
  }
  .modal-header h1 {
    font-size: 1rem;
  }
  .modal-header,
  .modal-content {
    padding: 0.5rem 1rem;
  }
}
@media (max-width: 285px) {
  .modal {
    font-size: 0.6rem !important;
  }
  .modal-header h1 {
    font-size: 0.8rem;
  }
}
