.modal-container {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  .modal-body {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    form {
      width: 50% !important;
      max-width: 55%;
      min-width: 50%;
      background-color: $yellow;
      padding: 30px 10px;
      border-radius: 10px;
    }
  }
}
