@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./scss/base/reset", "./scss/abstracts/variables";
@import "./scss/abstracts/mixins";

// General Font
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
// font-family: 'Comfortaa', cursive;

// Nav Font
@import url("https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap");
// font-family: 'Khand', sans-serif;

// Pages
@import "./scss/pages/about", "./scss/pages/blogs", "./scss/pages/contact",
  "./scss/pages/login", "./scss/pages/notFound";

// Components
@import "./scss/components/_header", "./scss/components/navbar",
  "./scss/components/footer", "./scss/components/social",
  "./scss/components/comments", "./scss/components/alert",
  "./scss/components/modal", "./scss/components/devModal";

body {
  background-color: $bgDark;
  color: $yellow;
  font-family: "Comfortaa", cursive;
  display: flex;
  flex-direction: column;

  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: 20px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999;
  }
  &::-webkit-scrollbar-track {
    background: #333;
  }
  // Standard version (Firefox only for now)
  scrollbar-color: #999 #333;

  input[type="submit"],
  button {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
    }
  }
}
