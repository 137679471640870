.footer-bg {
  background-color: $dark;
  box-shadow: rgba(180, 174, 174, 0.4) 0px 5px 70px;
  color: $white;
  font-family: "Khand", sans-serif;
  font-weight: 500;
  width: 100%;
  position: relative;
  z-index: 1;
  .footer-description {
    font-family: "Comfortaa", cursive;
    line-height: 1.7rem;
    font-size: 1.1rem;
  }
  .copyright {
    color: #9ca3af;
  }
}
