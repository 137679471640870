.dashboard__header {
  background-color: hsl(0, 0%, 15%);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  color: #ecf0ef !important;
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 60px;
  padding: 30px 20px 30px 40px;
  @media (max-width: 768px) {
    margin-left: 10px;
    a,
    span {
      font-size: 11px !important;
    }
    h1 {
      font-size: 13px;
    }
  }
  @media (max-width: 360px) {
    margin-left: 10px;
    a,
    span {
      font-size: 9px !important;
    }
    h1 {
      font-size: 11px;
    }
  }
  .header__right-side {
    font-weight: bold;
    margin-left: auto;
  }
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .dropdown-menu {
    animation: fadeInOut 0.2s ease-in-out;
  }
}
