.login {
  height: calc(100vh - 229px - 76px) !important ;
  @media (max-height: 835px) {
    height: calc(100vh - 229px) !important ;
  }
  @media (max-height: 760px) {
    height: calc(100vh - 150px) !important ;
  }
  @media (max-height: 760px) {
    height: 90vh !important ;
  }
  @media (max-height: 600px) {
    height: 100vh !important ;
  }
  .form-image {
    img {
      height: 100%;
    }
  }
}
.btns {
  @include buttons();
}
.box {
  position: relative;
  width: 380px;
  height: 420px;
  background-color: #1c1c1c;
  border-radius: 8px;
  overflow: hidden;
  &.register {
    height: 600px;
  }
  &::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(
      0deg,
      transparent,
      hsl(43deg, 100%, 61%),
      hsl(43deg, 100%, 61%)
    );
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
  }
  &::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(
      0deg,
      transparent,
      hsl(43deg, 100%, 61%),
      hsl(43deg, 100%, 61%)
    );
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
  }
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .form {
    position: absolute;
    inset: 2px;
    border-radius: 8px;
    background: #28292d;
    z-index: 10;
    padding: 10px 40px 50px;
    display: flex;
    flex-direction: column;
    h2 {
      color: hsl(43deg, 100%, 61%);
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.1em;
    }
    .inputBox {
      position: relative;
      width: 300px;
      input {
        position: relative;
        width: 100%;
        padding: 20px 10px 10px;
        background: transparent;
        border: none;
        outline: none;
        color: #23242a;
        font-size: 1em;
        letter-spacing: 0.05em;
        z-index: 10;
      }
      span {
        position: absolute;
        left: 0;
        padding: 20px 0 10px;
        font-size: 1em;
        color: #8f8f8f;
        pointer-events: none;
        letter-spacing: 0.05em;
        transition: 0.5s;
      }
    }
    .inputBox .inputBox input:valid ~ span,
    .inputBox input:focus ~ span {
      color: hsl(43deg, 100%, 61%);
      transform: translateX(0) translateY(-34px);
      font-size: 0.75em;
    }
    .inputBox i {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: hsl(43deg, 100%, 61%);
      border-radius: 4px;
      transition: 0.5s;
      pointer-events: none;
    }

    .inputBox input:valid ~ i,
    .inputBox input:focus ~ i {
      height: 44px;
    }
    .error-message {
      color: rgb(252, 85, 85);
      font-size: 0.8em;
      position: absolute;
    }
        
    .inputBox i.error {
      background: rgb(252, 85, 85) !important;
      margin-bottom: 5px;
    }
  }
}
