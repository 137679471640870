.comments {
  h2 {
    color: $yellow !important;
  }
  .comment-area {
    background-color: $dark !important;
    textarea {
      background-color: $dark !important;
    }
  }
  .comment-btn {
    background-color: $yellow !important;
    color: $dark !important;
  }
  article {
    background-color: $dark !important;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 1px 4px 0px;
  }
}
