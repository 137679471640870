.dashboard__aside__container {
  width: max-content;
  .menu-toggle {
    display: none;
  }
  @media (max-width: 768px) {
    .menu-toggle {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      height: 60px;
      width: 50px;
      background-color: hsl(0, 0%, 15%);
      color: #ecf0ef;
      z-index: 100;
      cursor: pointer;
    }
  }
  .dashboard__aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    height: 100%;
    background-color: hsl(0, 0%, 15%);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 20px;
    gap: 2rem;
    z-index: 1000;
    .menu-cross {
      display: none;
    }

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-200%);
      transition: all 0.2s ease-in-out;
      width: 100%;
      height: 100%;
      .dashboard__logo {
        max-width: 100px !important;
      }
      .menu-cross {
        display: block;
        position: absolute;
        right: 20px;
      }
      &.open {
        width: 50%;
        transform: translateX(0);
      }
      @media (max-width: 420px) {
        width: 100% !important;
      }
    }

    .dashboard__logo {
      margin: 40px 0 20px;
      text-align: center;
      width: 70%;
      max-width: 150px;
      img {
        width: 100%;
      }
    }
    .dashboard__nav {
      height: 100%;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        li {
          .active {
            background-color: hsla(173, 39%, 54%, 0.8) !important;
            color: #333 !important;
          }
          a {
            color: #b3bfbd !important;
            text-decoration: none;
            display: block;
            padding: 10px 1.2rem;
            margin-bottom: 10px;
            border-radius: 0.2rem;
            font-weight: 500;
            @media (max-width: 768px) {
              font-size: 0.8rem;
            }
            &:hover {
              background-color: rgba(90, 183, 172, 0.35) !important;
              color: #cefaef !important;
            }
          }
        }
      }
    }
  }
}
