.admin-login-page {
  background-image: linear-gradient(to right, #5ab7ac, #5ab7ac57);
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100%;
  color: #5ab7ac;
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 3em;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.2s ease-in-out;
    animation-duration: 0.2s;
    transition: all 0.3s ease-in-out;
    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(-10px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @media (max-width: 768px) {
      width: 90%;
      padding: 1em;
    }
    .logo {
      width: 100px;
      margin: 0 auto;
      display: block;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-radius: 10px;
      h1 {
        font-size: 2em;
        margin-bottom: 1em;
      }
      @media (max-width: 768px) {
        h1 {
          font-size: 1.5em;
          margin-bottom: 0.5em;
        }
      }
      .input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1em;

        .label-text {
          font-size: 1em;
          margin-bottom: 0.5em;
        }

        .input-field {
          background-color: #eee;
          color: black;
          padding: 0.8em;
          border: none;
          border-radius: 5px;
          margin-top: 0.3em;
          margin-bottom: 0.5em;
          width: 100%;
          font-size: 1.2em;
          transition: all 0.3s ease-in-out;
          &:focus {
            outline: none;
            box-shadow: 0 0 0 2px #5ab7ac;
            transition: all 0.5s ease-in-out;
          }
        }
      }
      .submit-button {
        background-color: #5ab7ac;
        color: #fff;
        padding: 0.8em 2em;
        border-radius: 5px;
        font-size: 1.2em;
        margin-top: 1em;
        width: 100%;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #5ab7acb9;
          transition: all 0.3s ease-in-out;
        }
      }
      .error {
        color: red;
        font-size: 1.2em;
        margin-top: 1.2rem;
        text-align: center;
        animation: fadeInOut 0.5s ease-in-out;
      }
      @keyframes fadeInOut {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
