@import "../scss/abstracts/mixins";

// Pages
@import "./scss/pages/login", "./scss/pages/blog";

// Components
@import "./scss/components/aside", "./scss/components/header";

.admin {
  background-color: #ecf0ef;
  width: 100%;
  overflow: hidden;
  .dashboard {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    .dashboard__right-side {
      width: calc(100% - 218px);
      min-height: 100vh;
      .dashboard__body {
        display: flex;
        flex: 1;
        height: 100%;
        .dashboard__main {
          flex: 1;
          padding: 0;
          .dashboard__section {
            width: 100%;
            color: #5ab7ac;
            padding: 20px;
            border-radius: 5px;
            h2 {
              margin: 0 0 10px 0;
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      .dashboard__right-side {
        width: 100%;
      }
      .dashboard__main {
        width: 100%;
      }
    }
  }
}
