.fab {
  position: fixed;
  right: 30px;
  bottom: 38px;
}
.btn {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 32px;
}
.btn.plus {
  font-size: 24px;
}
.fab > .btn {
  position: relative;
  z-index: 1;
  display: grid;
  place-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 0;
  background: $yellow;
  color: $bgDark;
  transition: 0.2s;
  rotate: 0;
}
.menu {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  translate: -50% -50%;
  border: 120px solid rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  rotate: -220deg;
  scale: 0.7;
  opacity: 0;
  transition: 0.4s;
  padding: 2rem;
}
.fab.open > .btn {
  rotate: 45deg;
}

.fab.open .menu {
  rotate: 0deg;
  scale: 1;
  opacity: 1;
}
.menu > .btn {
  position: absolute;
  transition: 0.3s;
}
.menu > .btn:hover {
  scale: 1.2;
}
.menu > .btn:nth-child(1) {
  left: 20px;
  top: -88px;
  color: #36c6a0;
}
.menu > .btn:nth-child(2) {
  left: -55px;
  top: -55px;
  color: #439ffd;
}
.menu > .btn:nth-child(3) {
  left: -88px;
  top: 20px;
  color: #bfca68;
}
