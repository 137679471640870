.about {
  .about-content {
    color: $white !important;
  }
  .about-body {
    p {
      color: $white !important;
    }
  }
  .paragraph {
    text-indent: 30px;
  }
  .skills {
    position: relative;
    padding: 0.3rem 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .tags-text {
      font-size: 1rem;
      font-weight: 900;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      &::before {
        content: attr(data-text);
        position: absolute;
        color: rgb(254 249 195);
        width: 0;
        overflow: hidden;
        transition: all 0.3s ease !important;
      }
    }
    &::before {
      content: "";
      width: 0%;
      height: 100%;
      position: absolute;
      background: rgba(133, 78, 14, 0.571);
      border-radius: 5px;
      right: 0;
      top: 0;
      transition: all 0.3s ease !important;
    }

    &:hover .tags-text::before,
    &:hover::before {
      width: 100%;
    }
  }
}
