.fblur {
  @include imgfilters(blur);
}
.fbrightness {
  @include imgfilters(brightness);
}
.fcontrast {
  @include imgfilters(contrast);
}
.fgrayscale {
  @include imgfilters(grayscale);
}
.fhuerotate {
  @include imgfilters(huerotate);
}
.finvert {
  @include imgfilters(invert);
}
.fopacity {
  @include imgfilters(opacity);
}
.fsaturate {
  @include imgfilters(saturate);
}
.fsepia {
  @include imgfilters(sepia);
}
