.contact {
  .form {
    .inputBox {
      textarea,
      input {
        position: relative;
        width: 100%;
        padding: 20px 10px 10px;
        background: transparent;
        border: none;
        outline: none;
        color: #23242a;
        font-size: 1em;
        letter-spacing: 0.05em;
        z-index: 10;
      }
      textarea {
        min-height: 220px;
      }
      span {
        position: absolute;
        left: 0;
        padding: 20px 0 10px;
        font-size: 1em;
        color: #8f8f8f;
        pointer-events: none;
        letter-spacing: 0.05em;
        transition: 0.5s;
      }
    }
    .inputBox .inputBox textarea:valid ~ span,
    .inputBox textarea:focus ~ span,
    .inputBox .inputBox input:valid ~ span,
    .inputBox input:focus ~ span {
      color: hsl(43deg, 100%, 61%);
      transform: translateX(0) translateY(-34px);
      font-size: 0.75em;
    }
    .inputBox i {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: hsl(43deg, 100%, 61%);
      border-radius: 4px;
      transition: 0.5s;
      pointer-events: none;
    }

    .inputBox input:valid ~ i,
    .inputBox input:focus ~ i {
      height: 44px;
    }
    .inputBox textarea:valid ~ i,
    .inputBox textarea:focus ~ i {
      height: 220px;
    }
  }
}
