.alert-container {
  position: fixed;
  width: fit-content;
  right: 0;
  top: 100px;
  z-index: 100;
  overflow: hidden;
  .alert-msg {
    position: relative;

    padding: 0.8rem 1.5rem;
    right: -120%;
    transition: all 0.3s ease;
    color: #fff !important;
  }
  .alert-msg.active {
    animation: slideIn 2s ease-in-out;
    animation-fill-mode: forwards;
    @keyframes slideIn {
      from {
        right: -120%;
      }
      to {
        right: 0;
      }
    }
  }
  //TODO: Add if else for alert.
  .alert-msg.hide.success,
  .alert-msg.active.success {
    background-color: rgb(93, 174, 93);
  }
  .alert-msg.hide.error,
  .alert-msg.active.error {
    background-color: rgb(235, 112, 112);
  }
  .alert-msg.hide.warning,
  .alert-msg.active.warning {
    background-color: rgb(235, 211, 112);
  }
  .alert-msg.hide {
    animation: slideOut 3s ease-in-out;
    animation-fill-mode: forwards;
    @keyframes slideOut {
      from {
        right: 0;
      }
      to {
        right: -120%;
      }
    }
  }
}
