nav {
  background-color: $bgDark !important;
  .right-side {
    color: $white;
    .right-item {
      transition: all 0.3s ease-in-out !important;
      &:hover {
        color: $yellow !important;
        cursor: pointer;
      }
      &.active {
        color: $yellow !important;
      }
    }

    .notifications {
      position: relative;
      overflow: visible;
      padding: 0;
      .notification {
        font-size: 1.2rem;
      }
      &::before {
        content: attr(data-count);
        position: absolute;
        top: -0.5em;
        right: -0.5em;
        width: 1em;
        height: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: red;
        color: $white;
        padding-top: 1px;
      }
    }

    .language {
      background-color: $bgDark;
      color: $white;
    }
    .language select {
      background-color: $bgDark;
      color: $white;
    
      &:focus {
        outline: none;
      }
    
      option {
        background-color: $bgDark;
        color: $white;
      }
    }
  }
  a:not(.notifications, .right-item) {
    color: $white !important;
    font-family: "Khand", sans-serif;
    font-weight: normal;
    letter-spacing: 0.05rem;
    transition: all 0.3s ease-in-out !important;
    &:hover:not(.notifications) {
      color: $yellow !important;
    }
  }

  @media screen and (max-width: 991px) {
    .logo {
      margin-bottom: 1rem;
    }
  }
}
.active {
  li {
    color: $yellow !important;
  }
}